<template>
  <div>
    <div class="confirm-icon">
      <div class="confirm-icon__icon">
        <confirm-icon/>
      </div>
      <div class="confirm-icon__text">
        <span>Congratulations!</span>
        <span>Payment has been successfully processed</span>
      </div>
    </div>
    <div class="confirm-description">
      <p>You can view your purchase in your
        <app-link :to="{name: 'profile'}" class="confirmation-link">My Account</app-link>
        tab
      </p>
      <p>
        Receipt confirmation has been sent to your email inbox that you have registered with us.
        If there is any problems with your order please visit <app-link
          :to="supportPageUrl"
          class="confirmation-link"
        >the support page</app-link>.
      </p>
    </div>
  </div>
</template>

<script>
  import ConfirmIcon from "../../assets/img/confirmIcon";
  import AppLink from "../base-components/AppLink";
  import {mapState} from "vuex";
  export default {
    name: "OrderConfirmation",
    components: {AppLink, ConfirmIcon},
    computed: {
      ...mapState({
        channelMenu: state => state.channel.menu,
      }),
      supportPageUrl(){
        const support = this.channelMenu?.find(({name}) => name.toLowerCase() === 'support')
        return support?.url
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "src/assets/css/mixins";

  .confirm-icon{
    color: var(--secondary);
    display: flex;
    gap: 3.1rem;
    font-weight: 500;
    align-items: center;
    @include media-max(sm){
      gap: 1rem;
      .confirm-icon__icon{
        max-width: 3rem;
        svg{
          width: 100%;
        }
      }
    }
  }

  .confirm-icon__text{
    display: flex;
    flex-direction: column;
    color: var(--font-color);
    gap: 0.95rem;
    line-height: 1.15;
    text-align: left;
    span:nth-child(1){
      font-size: 1.3rem;
      @include media-max(sm){
        font-size: 1.1rem;
      }
    }
    @include media-max(sm){
      font-size: .9rem;
    }
  }
  .confirm-description{
    margin-top: 3.8rem;
    text-align: left;
    color: var(--font-color);
    font-weight: 500;
    p:first-child{
      margin-bottom: 2rem;
    }
    @include media-max(sm){
      margin-top: 1.8rem;
      font-size: 1rem;
    }
  }
</style>