<template>
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="48.5" stroke="currentColor" stroke-width="3"/>
    <path
        fill="currentColor"
        d="M68.75 39.5834L43.75 64.5834L32.2917 53.125L35.2292 50.1875L43.75 58.6875L65.8125 36.6459L68.75 39.5834Z"
    />
  </svg>
</template>

<script>
  export default {
    name: "confirmIcon"
  }
</script>